import { APP_HOST } from '@/app.config';

export default class LaboratoryApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getLaboratoryBranchList(params) {
    const { data } = await this.api.get(`${APP_HOST}v3/laboratory/branch/list`, { params });
    return data;
  }

  async getLaboratoryServicePrice(params) {
    const { data } = await this.api.get(`${APP_HOST}v3/laboratory/partner-service/get-price`, { params });
    return data;
  }

  async getHistoryOrder(id) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/laboratory/order/${id}/history`);
    return data;
  }

  async getPreparationList(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/laboratory/preparation/list`, { params });
    return data;
  }

  async getPreparation(id) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/laboratory/preparation/get?id=${id}`);
    return data;
  }

  async createPreparation(params) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/laboratory/preparation/create`, params, {
      'Content-Type': 'multipart/form-data',
    });
    return data;
  }

  async deletePreparation(id) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/laboratory/preparation/delete?id=${id}`);
    return data;
  }

  async updatePreparation(id, params) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/laboratory/preparation/update?id=${id}`, params);
    return data;
  }
}
